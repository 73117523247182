
.gallery-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  justify-content: center;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    width: 0;
  }

  .react-grid-layout {
    @media (max-width: 767px) {
      padding-top: 56px !important;
    }
  }

  .react-grid-layout {
    width: 100%;
    background: #fff;
    padding: 0px 0px 0px 10px !important;
  }
}

.layout div.react-grid-item {
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: -10px;

  & > div {
    height: 100%;
  }
}

/* Если вы хотите стилизовать изображения в ячейках сетки, вы можете добавить следующее правило CSS */

.layout div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.react-grid-item {
  margin: 0;
  padding: 0;
}