@import './variables';

.pressPhoto {
  width: 50%;
  height: 100vh;
  min-height: 100vh;
  background: url("../img/press1.jpg") no-repeat;
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: 979px) and (max-height: 400px) and (orientation: landscape) {
    height: auto;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 375px;
  }
}

#sectionPress {
  display: flex;

  @media (max-width: 767px) {
    padding-top: 61px;
    flex-direction: column-reverse;
  }

  .page-title {
    @media (max-width: 1205px) {
      font-size: 96px;
      line-height: 96px;
    }
  }
  
  .bioContent {
    max-height: 100vh;
    min-height: 100vh;
    height: 100vh;
    overflow: hidden;
    @media (max-width: 767px) {
      height: auto;
      padding-bottom: 20px;
    }

    @media only screen and (max-width: 979px) and (max-height: 400px) and (orientation: landscape) {
      max-height: max-content;
      height: auto;
      overflow: inherit;
    }
    @media (max-width: 767px) {
      max-height: max-content;
      overflow: inherit;
    }
  }
  .pressCotainer {
    -ms-overflow-style: none;  /* IE и Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 767px) {
      max-height: max-content;
      height: max-content !important;

      div {
        position: relative !important;
      }
    }

    ul {
      padding-right: 24px;
      li{
        display: flex;
        align-items: flex-start;
        margin-bottom: 24px;
        gap: 58px;
        justify-content: space-between;
        @media (max-width: 997px) {
          gap: 20px;
        }


        p {
          margin: 0;
          font-size: 16px;
          @media (max-width: 997px) {
            font-size: 12px;
          }

          span {
            font-weight: 600;
          }
        }

        .readMore {
          font-size: 14px;
          position: relative;
          display: flex;
          align-items: center;
          z-index: 2;
          @media (max-width: 997px) {
            font-size: 12px;
          }

          &:after {
            content: "";
            width: 16px;
            height: 16px;
            background: url('../img/arrow-l.svg') no-repeat;
            background-size: contain;
            background-position: center;
            display: inline-block;
            margin-left: 5px;
            transform: rotate(180deg);
          }
          &:before {
            content: "";
            left: -10px;
            width: 0;
            height: 19px;
            background-color: #fff;
            position: absolute;
            transition: width 0.2s ease-out;
            z-index: -1;
            transform: matrix(1, 0, -0.5, 1, 0, 0);
            transition: 0.2s ease;
          }
          &:hover {
            color: #6536FF;
            &:before {
              width: 81px;
            }
            &:after {
              background: url('../img/arrow-l-d.svg') no-repeat;
            }
          }
        }
      }
    }
  }

  .contentBlock {
    margin-bottom: 0;
  }
}

.verticalLine {
  position: absolute;
  width: 6px;
  height: 100%;
  right: 2px;
  bottom: 2px;
  top: 0;
  border-radius: 10px;
  background: #8561FC;

  .verticalThumb {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    height: 198px;
    transform: translateY(0px);
    border-radius: 10px;
    background: #B29AFF;
  }
}