@import './font-home';
@import './variables';

#section0 {
  position: relative;
  background: url('../img/main-banner4.webp');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  max-height: 100%;
  width: 100vw;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgb(0 0 0 / 40%);
  }

  &.hide-section {
    transition: 0.8s ease;
  }

  &.show {
    opacity: 1;
  }

  .main-social {
    position: absolute;
    right: 60px;
    top: 60px;
    @media (max-width: 1360px) {
      top: auto;
      bottom: 60px;
    }
    @media (max-width: 997px) {
      right: 20px;
    }
    @media (max-width: 767px) {
      display: none;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;

      li {
        a {
          display: block;
          width: 42px;
          height: 42px;
          background-position: center;
          transition: 0.2s ease;

          svg {
            width: 45px;
            height: 45px;

            @media (max-width: 1360px) {
              width: 35px;
              height: 35px;
            }
          }

            &:hover svg path{
              transition: 0.2s ease;
              fill: #714BEE;
            }


          @media (max-width: 1360px) {
            width: 31px;
            height: 31px;
          }
        }
      }
    }
  }

  .social-menu {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }

  .logo {
    position: absolute;
    left: 60px;
    top: 60px;

    svg {
      max-width: 100%;
    }
    @media (max-width: 997px) {
      left: 20px;
      top: 15px;
      width: 42px;
    }
  }
}


.intro {
  position: absolute;
  left: 35px;
  bottom: 60px;
  @media (max-width: 997px) {
    left: 9px;
    bottom: 60px;
  }

  .head-tag {
    color: #fff;
    font-size: 176px;
    font-style: normal;
    line-height: 140px; /* 79.545% */
    letter-spacing: -4.6px;
    text-transform: uppercase;
    font-family: 'Ailerons', sans-serif;
    padding-bottom: 34px;
    user-select: none;

    @media (max-width: 997px) {
      font-size: 112px;
      line-height: 90px; /* 80.357% */
      letter-spacing: -3.2px;
    }
    @media (max-width: 767px) {
      font-size: 110px;
      letter-spacing: -5px;
    }

    span {
      display: block;
      padding-top: 0
    }
  }

  .copyright {
    color: $color_white;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: -34px;
    padding-left: 16px;
    display: block;
    user-select: none;
    @media (max-width: 997px) {
      padding-left: 10px;
    }
  }
}

#section0 .main-social.social-menu {
  right: auto;
  left: 20px;
  bottom: 105px;

  ul {
    flex-direction: row;
    gap: 12px;

    li a {
      padding: 0;
    }
  }
}
