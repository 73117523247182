@import './variables';

#sectionTeam {
  height: 100%;

  @media (max-width: 767px) {
    padding-top: 61px;
  }

  .teamContainer {
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    @media (max-width: 767px) {
      height: 100%;
    }

    .teamElement {
      overflow: hidden;
      width: 16.666%;
      height: 33.333%;
      display: flex;
      justify-content: center;
      align-items: baseline;



      img {
        width: 100%;

        @media (min-height: 1067px) and (min-width: 987px) {
          width: auto;
          height: 100%
        }
        @media (min-height: 1067px) and (min-width: 1588px) {
          width: 100%;
          height: auto;
        }

        @media (max-width: 920px) {
          width: auto;
          height: 100%;
        }
        @media (max-width: 767px) {
          width: 100%;
          height: auto;
        }
        @media only screen and (max-width: 979px) and (max-height: 400px) and (orientation: landscape) {
          width: 100%;
          height: auto;
        }
      }

      @media (max-width: 767px) {
        width: 33.333%;
        height: 255px;
      }
      @media only screen and (max-width: 979px) and (max-height: 400px) and (orientation: landscape) {
        width: 16.333%;
      }
      @media (max-width: 585px) {
        height: 206px;
      }
      @media (max-width: 485px) {
        height: 136px;
      }
    }
  }
}