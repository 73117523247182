@import './variables';

#sectionPartners {
  align-items: stretch;
  display: flex;
  min-height: 100vh;

  .director {
    .contact-der {
      display: flex;
      gap: 10px;
    }
    p {
      margin-top: 0;
    }
    a {
      display: inline-flex !important;
    }
    a.messengerLinks {
      width: 17px;
      height: 17px;
      display: inline-flex;
      svg {
        width: 100%;
        margin-top: -3px;
      }
      &.whatsapp {
        background: url("../img/whatsap.svg") no-repeat;
        background-size: contain;
      }
    }
  }

  .contentBlock {
    @media (max-width: 1363px) {
      max-width: 538px;
    }
  }

  .bioContent {
    padding: 0 20px 35px 90px;


    @media (min-width: 1363px) {
      padding: 0 90px 35px 90px;
    }

    @media (max-width: 997px) {
      padding: 0 20px 35px 20px;
      height: max-content;
      min-height: 100vh;
      min-height: 100vh;
    }
    @media (max-width: 767px) {
      padding: 35px 10px 10px 10px;
    }
  }

  .page-title {
    margin-top: 13.9%;
    @media (max-width: 767px) {
      margin-top: 0;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    padding-top: 61px;
  }

  .partnersPhoto {
    background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url('../img/partners1.webp');
    background-size: cover;
    background-position: center;
    width: 50%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      width: 100%;
      padding: 80px 0;
      justify-content: center;
    }
  }

  .logoContainer {
    padding: 0 60px;
    @media (max-width: 997px) {
      padding: 0 32px;
    }
    @media (max-width: 767px) {
      max-width: 385px;
    }

    .logoRow {
      display: flex;
      justify-content: space-between;
      gap: 35px;

      align-items: center;

      &.row-full {
        margin-bottom: 83px;
        @media (max-width: 997px) {
          margin-bottom: 47px;
        }
      }


      &.row-multy {
        span {
          width: 50%;
          text-align: center;
        }

        img {
          max-width: 50%;
        }
      }

      &.row-middle {
        margin-bottom: 60px;
        @media (max-width: 997px) {
          margin-bottom: 33px;
        }
      }

      img {
        max-width: 100%;
        height: auto;

        @media (max-width: 997px) {
          &.df-logo {
            max-width: 146px;
          }
          &.motul-logo {
            max-width: 168px;
          }
          &.kit-logo {
            max-width: 71px;
          }
          &.oz-log {
            max-width: 151px;
          }
        }
      }
    }
  }
}