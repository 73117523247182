//colors
$main_color: #714BEE;
$second_main_color: #46a5d7;
$color_white: #fff;
$main_text_color: #333333;
$icon_c_hover: #ff8a00;
$list_c_hover: #00bf72;
$btn_c_hover: #3b8bb6;
$list_color_secondary: #717885;


// fonts
$main_title_size: 30px;

// Functions
$menuItems: 7;
$transition: 100;
$imgItems: 800;
$transitionImg: 10;