@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Semibold.woff') format('woff');
  src: url('../fonts/Gilroy-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Regular.woff') format('woff');
  src: url('../fonts/Gilroy-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Kazmann Sans';
  src: url('../fonts/Kazmann-Sans.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}