@import './variables';
@import './fonts';

body,
html {
  margin: 0;
  padding: 0;
  //overflow-x: hidden;
  font-family: "Gilroy", sans-serif;
  height: 100%;
}
#root, #root>div{
  height: 100%;
}
a {
  text-decoration: none;
  color: $color_white
}

a:hover {
  text-decoration: none
}
.active {
  color: #1644ba
}
table {
  border-spacing: 0
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
  color: $color_white
}

#section2 .second-block-title,
#section4,
#section4 .second-block-title {
  color: $color_white
}
.main_container {
  width: 100vw;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  
  @media (max-height: 600px) {
    min-height: 100vh;
  }
}

.goHome {
  color: $color_white;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  position: fixed;
  top: 40px;
  left: 74px;
  padding: 0px 24px 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  letter-spacing: 1px;
  z-index: 999;

  &.top {
    bottom: auto;
    top: 40px;

    @media (max-width: 997px) {
      left: 20px;
      bottom: auto;
      top: 24px;
    }
    @media (max-width: 767px) {
      top: 0;
      left: 0;
    }
  }

  @media (max-width: 997px) {
    left: 20px;
    bottom: 24px;
  }

  @media (max-width: 767px) {
    bottom: auto;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: flex-start;
    padding: 25px 10px;
    background: rgba(113, 75, 238, 1);
  }

  &:after {
    content: "";
    left: 0;
    width: 112px;
    bottom: 0;
    height: 100%;
    background-color: #714BEE;
    position: absolute;
    transition: width 0.2s ease-out;
    z-index: -1;
    transform: matrix(1, 0, -0.5, 1, 0, 0);
    transition: 0.2s ease;
    @media (min-width: 767px) {
      bottom: -9px;
      height: 32px;
    }
    @media (max-width: 767px) {
      transform: none;

    }
  }
  &:hover:after {
    background-color: #6536FF;
  }

  &:before {
    content: "";
    width: 14px;
    height: 14px;
    background: url("../img/arrow-l.svg") no-repeat;
    background-size: contain;
    background-position: center;
    display: inline-block;
    margin-right: 5px;
    margin-top: 0px;
  }
}
.revers {
  @media (min-width: 768px) {
    .goHome {
      color: #714BEE;
      &:after {
        background-color: $color_white;
      }
      &:hover:after {
        background-color: $color_white;
      }
      &:before {
        background: url("../img/arrow-l-d.svg") no-repeat;
      }
    }
  }

}

.page-title {
  color: $color_white;
  font-family: Kazmann Sans, sans-serif;
  font-size: 156px;
  font-style: normal;
  font-weight: 400;
  line-height: 129px; /* 82.692% */
  text-transform: uppercase;
  margin-bottom: 70px;

  @media (max-width: 997px) {
    font-size: 96px;
    line-height: 96px; /* 100% */
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
}

.preload-bio {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.homePre {
    .logo-anim {
      height: 221px;
      width: 221px;
      @media (max-width: 997px) {
        height: 110px;
        width: 110px;
      }
      span {
        &:before {
          width: 221px;
          height: 221px;
          background-size: 221px 221px;
          @media (max-width: 997px) {
            height: 110px;
            width: 110px;
            background-size: 110px 110px;
          }
        }
      }
    }
  }
  .logo-anim {
    height: 100px;
    width: 100px;
    position: relative;
    span {
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      transition: width .6s linear;

      &.show-l {
        left: 0;
        &:before {
          background-position: left;
          left: 0;
        }
      }
      &.show-r {
        right: 0;
        &:before {
          background-position: right;
          right: 0;
        }
      }

      &:before {
          content: '';
          width: 100px;
          height: 100px;
          background: url("../img/logo-pre.svg") no-repeat;
          position: absolute;
          background-size: 100px 100px;
          top: 0;
        }

      &.second-el {
        bottom: -5px;
      }

    }
  }
}
