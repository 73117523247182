@import './variables';

#sectionGallery {
  height: 100%;
  min-height: 100%;
  width: 100vw;
  max-width: 100%;
  //overflow: hidden;

  @media (max-width: 767px) {
    background-image: linear-gradient(rgba(113, 75, 238, 0.90), rgba(113, 75, 238, 0.90)), url(../img/bioBg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  &.overAuto {
    //overflow-y: scroll;
  }

.slick-arrow {
    width: 102px;
    height: 112px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    bottom: -56px;
    top: auto;
    right: 60px;
    z-index: 999;

    &:hover {
      transition: 0.3s ease;
      background: #6536FF !important;
    }

    @media (max-width: 767px) {
      width: 83px;
      height: 60px;
      bottom: -31px;
      right: 0;
    }

    &:before {
      background: url("../img/bioArrow.svg") no-repeat;
      background-position: center;
      content: "";
      width: 6px;
      height: 12px;
      display: block;
      opacity: 1;
    }

    &.slick-prev {
      left: auto;
      right: 162px;
      @media (max-width: 767px) {
        right: 83px;
      }

      &:before{
        transform: rotate(180deg);
      }
    }
  }

  &.zoomNone .galleryContainer img {
    transition: 0.1s ease;
    @for $i from 0 through $imgItems {
      &:nth-child(#{$i + 1}) {
        transition-delay: $transitionImg+(5ms*($i));
      }
    }
  }
  &.zoomNone {
    @media (max-width: 767px) {
      overflow: auto;
    }
  }
}
.galleryContainer {
  display: flex;
  flex-wrap: wrap;

    img {
      display: block;
      padding: 1px;
      opacity: 0;
      transition: 0.3s ease;

    }

  &.block-show {
    justify-content: center;
    @media (max-width: 767px) {
      background: #fff;
      padding-top: 56px;
    }
  }
  &.block-show img.img-loaded {
    opacity: 1;
  }
}

.fullImgContainer {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100%;

  .fullImgItem {
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;

    &.wide-image {

      max-height: 100%;
      width: 100vw;
      max-width: 100vw;
      object-fit: cover;
      object-position: top;
      @media (max-width: 767px) {
        height: auto;
      }
    }
  }
}

.gallerySliderContainer {
  height: 100%;
  .slick-slider {
    height: 100%;
    .slick-list {
      height: 100%;

      .slick-track {
        height: 100%;
        .slick-slide {
          display: flex;
        }
      }
    }
  }
}

.allPhotos {
  position: fixed;
  z-index: 999;
  top: 60px;
  right: 60px;
  background: transparent;
  border: none;
  color: $color_white;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding: 0 19px;
  cursor: pointer;
  font-family: "Gilroy", sans-serif;
  letter-spacing: 1px;
&.hidden {
  display: none !important;
}
  @media (max-width: 767px) {
    right: 10px;
    top: 0;
    height: 56.8px;
    z-index: 99999;
    font-size: 14px;
    font-weight: 700;
  }

  &:after {
    content: "";
    left: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    background-color: #714BEE;
    position: absolute;
    z-index: -1;
    transform: matrix(1, 0, -0.5, 1, 0, 0);
    transition: 0.2s ease;

    @media (min-width: 767px) {
      bottom: -6px;
      height: 30px;
    }

    @media (max-width: 767px) {
      transform: none;
    }
  }
  &:before {
    @media (max-width: 767px) {
      content: "";
      width: 14px;
      height: 14px;
      background: url("../img/arrow-l.svg") no-repeat;
      background-size: contain;
      background-position: center;
      display: inline-block;
      position: absolute;
      margin-right: 2px;
      right: 0;
      top: 23px;
      transform: rotate(180deg);
      margin-top: -2px;
    }
  }
  &:hover:after {
    background-color: #6536FF;

    @media (max-width: 767px) {
      background-color: transparent;
    }
  }
}

.galleryContainer {
  .innerPageMenu {
    display: none !important;
  }
  .innerPageMenu.showMenu {
    display: flex !important;
  }
}
