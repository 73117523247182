#sectionContacts {
  display: flex;
  min-height: 100vh;
  align-items: stretch;
  position: relative;
  .bioContent {
    padding-top: 90px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }
  .director {
    .contact-der {
      display: flex;
      gap: 10px;
    }
    p {
      margin-top: 0;
    }
    a {
      display: inline-flex !important;
    }
    a.messengerLinks {
      width: 29px;
      height: 29px;
      display: inline-flex;
      svg {
        width: 100%;
      }
      &.whatsapp {
        svg {
          width: 26px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }

  .bioContent {
    background-image: linear-gradient(rgba(113, 75, 238, 0.9), rgba(113, 75, 238, 0.9)), url('../img/contactsBg1.webp');
    background-size: cover;

    @media only screen and (max-width: 979px) and (max-height: 400px) and (orientation: landscape) {
      padding-bottom: 50px;
    }
    @media (max-width: 767px) {
      padding-top: 100px;
      background-position: top;
    }
  }

  .mainContact {
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-bottom: 15px;

    @media (max-width: 997px) {
      margin-bottom: 24px;
    }

    a {
      font-size: 24px;
      font-weight: 400;
      display: flex;
      align-items: center;

      @media (max-width: 997px) {
        font-size: 16px;
      }

      &:hover {
        svg path{
          transition: 0.2s ease;
          fill: #CCBDFF;
          width: 100px;
        }
        svg g:nth-child(2) path{
          fill: #7247DB;
        }
      }

      span {
        margin-right: 19px;
        display: inline-block;
        width: 43px;
        height: 43px;

        @media (max-width: 997px) {
          width: 34px;
          height: 34px;
        }
      }
    }
  }
  .mediaContact {
    display: flex;
    gap: 10px;
    margin-bottom: 24px;

    @media (max-width: 997px) {
      margin-bottom: 32px;
    }

    a {
      width: 43px;
      height: 43px;

      &:hover {
        svg path{
          transition: 0.2s ease;
          fill: #CCBDFF;
          width: 100px;
        }
      }

      @media (max-width: 997px) {
        width: 34px;
        height: 34px;
      }
    }
  }
  .contentBlock {
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .allDrift {
    display: flex;
    gap: 35px;

    p {
      font-size: 20px;
      font-weight: 600;
      margin: 0;

      @media (max-width: 997px) {
        font-size: 16px;
      }
    }
    a {
      font-size: 12px;
      font-weight: 400;
      position: relative;
      display: flex;
      align-items: center;
      z-index: 2;

      &:after {
        content: "";
        width: 16px;
        height: 16px;
        background: url('../img/arrow-l.svg') no-repeat;
        background-size: contain;
        background-position: center;
        display: inline-block;
        margin-left: 5px;
        transform: rotate(180deg);
      }
      &:before {
        content: "";
        left: -10px;
        width: 0;
        height: 30px;
        background-color: #6536FF;
        position: absolute;
        transition: width 0.2s ease-out;
        z-index: -1;
        transform: matrix(1, 0, -0.5, 1, 0, 0);
        transition: 0.2s ease;
      }
      &:hover:before {
        width: 90px;
      }
    }

  }

  .coordinate {
    position: relative;
    margin-top: 24px;

    @media (max-width: 767px) {
      position: relative;
      bottom: auto;
      margin-top: 42px;
    }
    p {
      margin: 0;

      span {
        user-select: none;
      }
    }
  }

  .map {
    width: 50%;
    position: relative;
    overflow: hidden;
    @media (max-width: 767px) {
      height: 340px;
      width: 100%;
    }
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: url("../img/map.jpg") no-repeat;
      background-size: cover;
      bottom: 0;
      right: 0;
      z-index: -1;
      background-size: cover;
      background-position-x: 71%;
      background-position-y: 0;

      @media (max-width: 997px) {
        background-position-x: 64%;
      }
      @media (max-width: 885px) {
        background-position-x: 72%;
      }
      @media (max-width: 767px) {
        background-size: 135% auto;
        background-position-x: 84%;
        background-position-y: bottom;
      }
      @media (max-width: 550px) {
        background-size: 135% auto;
        background-position-x: 84%;
        background-position-y: 0;
      }
      @media (max-width: 410px) {
        background-size: 135% auto;
        background-position-x: 84%;
        background-position-y: 0;
      }
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

}

.contactsTransparent .goHome:after {
  @media (max-width: 767px) {
    background: transparent;
  }
}