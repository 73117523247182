#preloaderHome {
  width: 100vw;
  height: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  background-position-y: bottom;
  background-position-x: right;
  @media (max-width: 440px) {
    background-position-x: -450px;
  }

  img {
    @media (max-width: 997px) {
      width: 110px;
      height: 110px;
    }
  }
  &.scaleCustom {
    background-size: 132%;
    background-position-y: center;

    @media (max-width: 997px) {
      background-size: cover;
    }
    @media (max-width: 440px) {
      background-position-x: center;
    }
  }
  &.preshow {
    opacity: 1;
  }
  &.hide {
    transition: 0.8s ease;
    opacity: 0;
    z-index: -1;
  }


  &.disable {
    display: none;
    z-index: -1;
  }
}

// Color Variables ======================================
$green: #714BEE;
$gray-light: #eef1f3;

// Bar Variables ========================================
$bar-size: 5px;
$bar-radius: 60px;
$bar-bg: rgba(0, 0, 0, 0.2);

body {
  background: $gray-light;
}

// Animation =============================================
// Edit these at random intervals to change the animation.
@keyframes loader {

  0% {
    width: 0;
  }

  20% {
    width: 10%;
  }

  25% {
    width: 24%;
  }

  43% {
    width: 41%;
  }

  56% {
    width: 50%;
  }

  66% {
    width: 52%;
  }

  71% {
    width: 60%;
  }

  75% {
    width: 76%;

  }

  94% {
    width: 86%;
  }

  100% {
    width: 100%;
  }

}

// Bar ============================================
.progress-bar {
  border-radius: $bar-radius;
  overflow: hidden;
  width: 100%;
  position: absolute;
  bottom: 20%;

  span {
    display: block;
  }

}

.bar {
  background: $bar-bg;
}

.progress {
  animation: loader 4s ease forwards;
  // Change the animation fill mode 'infinite' to 'forwards' to stop the animation from repeating.
  background: $green;
  color: #fff;
  padding: $bar-size;
  width: 0;
}

