@import './variables';

.bioContent {
  background-image: linear-gradient(rgba(113, 75, 238, 0.90), rgba(113, 75, 238, 0.90)), url(../img/bioBg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 40px 20px 35px 90px;
  width: 50%;
  box-sizing: border-box;

  @media (min-height: 800px) {
    padding: 100px 20px 35px 90px;
  }

  @media (max-width: 997px) {
    padding: 40px 20px 35px 20px;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 35px 10px 10px 10px;
  }

  p {
    color: $color_white;
  }
}

.contentBlock {
  margin-bottom: 40px;
  @media (max-width: 997px) {
    margin-bottom: 24px;
  }
  .specialTitle {
    color: $color_white;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 14px;
    @media (max-width: 997px) {
      font-size: 12px;
    }
  }

  ul li {
    color: $color_white;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 19.2px */

    &:before {
      content: "";
      width: 3px;
      height: 3px;
      display: inline-block;
      background: #fff;
      border-radius: 50%;
      position: relative;
      top: -3px;
      margin-right: 5px;
    }

    @media (max-width: 997px) {
      font-size: 12px;
    }
  }
  ul {
    &.nonStyle li:before {
      display: none;
    }
  }
}

.rtLink {
  display: flex;
  gap: 13px;

  img {
    @media (max-width: 997px) {
      width: 41px;
      height: 35px;
    }
  }

  p {
    margin: 0;
    max-width: 415px;
    color: $color_white;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;
    display: flex;
    @media (max-width: 997px) {
      font-size: 12px;
      max-width: 265px;
    }
  }
  .externalLink {
    color: $color_white;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 2;
    @media (max-width: 997px) {
      font-size: 12px;
    }

    &:after {
      content: "";
      width: 16px;
      height: 16px;
      background: url("../img/arrow-l.svg") no-repeat;
      background-size: contain;
      background-position: center;
      display: inline-block;
      margin-left: 5px;
      transform: rotate(180deg);

    }
    &:before {
      content: "";
      left: -10px;
      width: 0;
      bottom: 0;
      height: 19px;
      background-color: #6536FF;
      position: absolute;
      transition: width 0.2s ease-out;
      z-index: -1;
      transform: matrix(1, 0, -0.5, 1, 0, 0);
      transition: 0.2s ease;
    }
    &:hover:before {
      width: 100px;
    }
  }

}
