@import './variables';

#sectionBio {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;

  .logoMob {
    display: none;
    position: fixed;
    top: 7px;
    right: calc(50% - 32px);
    z-index: 9999;
    @media (max-width: 767px) {
      display: block;
    }
  }

  .bioContent {
    padding: 40px 20px 28px 90px;

    @media (max-width: 767px) {
      padding: 35px 10px 45px 10px;
    }
  }
  .rtLink {
    img {
      @media (max-width: 600px) {
        width: 31px;
        height: 100%;
      }
    }
    p {
      @media (max-width: 600px) {
        width: 270px;
        font-size: 11px;
      }
    }
  }
  .slick-slider {
    min-height: 100%;
  }
  .slick-arrow {
    width: 102px;
    height: 112px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    bottom: -56px;
    top: auto;
    right: 0;
    z-index: 999;

    &:hover {
      transition: 0.3s ease;
      background: #6536FF !important;
    }

    @media (max-width: 767px) {
      width: 83px;
      height: 60px;
      bottom: -31px;
    }

    &:before {
      background: url("../img/bioArrow.svg") no-repeat;
      background-position: center;
      content: "";
      width: 6px;
      height: 12px;
      display: block;
      opacity: 1;
    }

    &.slick-prev {
      left: auto;
      right: 102px;
      @media (max-width: 767px) {
        right: 83px;
      }

      &:before{
        transform: rotate(180deg);
      }
    }
  }


  @media (max-width: 767px) {
    flex-direction: column;
    padding-top: 61px;
  }

  .bioGalery {
    width: 50%;
    position: relative;
    background: #000;

    .slick-slider {
      @media (max-width: 767px) {
        max-height: 375px;
      }
    }
    .slick-slide {

      div div {
        display: flex !important;
        justify-content: center;
        overflow: hidden;
      }
    }
    img {
      height: 100vh;
      min-height: 100vh;
      width: auto;
      margin-left: -15%;

      @media (max-width: 767px) {
        height: auto;
        margin-left: 0;
        width: 100%;
        min-height: auto;
      }
      @media (max-width: 600px) {
        height: 100%;
        width: auto;
        max-height: 456px;
      }
      @media (max-width: 400px) {
        margin-left: -30%;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      max-height: 375px;
      overflow: hidden;
    }
  }
}