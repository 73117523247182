.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  background: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  z-index: 99999999;
  transition: 2s ease;
}

.loader.hidden {
  transition: 2s ease;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

#sectionVideo {


  .swiper-button-next, .swiper-button-prev {
    width: 102px;
    height: 112px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    bottom: 0;
    top: auto;
    right: 60px;
    z-index: 999;
    background: rgb(113 75 238 / 90%);

    &:hover {
      transition: 0.3s ease;
      background: #6536FF !important;
    }

    @media (max-width: 767px) {
      width: 84px;
      height: 50px;
      bottom: 0;
      right: 0;
    }

    &:before {
      background: url("../img/bioArrow.svg") no-repeat;
      background-position: center;
      content: "";
      width: 6px;
      height: 12px;
      display: block;
      opacity: 1;
    }

    &:after {
      content: none;
    }
  }

  .swiper-button-prev {
    left: auto;
    right: 162px;
    @media (max-width: 767px) {
      right: 83px;
    }

    &:before {
      transform: rotate(180deg);
    }
  }

  .videoContainer {
    max-height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    position: relative;
    @media (max-width: 767px) {
      padding-bottom: 20px;

      video {
        height: 100dvh !important;
      }
    }
  }

  .swiper-wrapper {
    max-height: 100vh;
    max-height: 100vh;
    align-items: center;
  }

  @media (max-width: 997px) {
    background: #000;
  }

  .slick-slider {
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
  }

  .slick-track {
    background-image: linear-gradient(rgba(0, 0, 0, 0.90), rgba(0, 0, 0, 0.90)), url(../img/bioBg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .slick-arrow {
    width: 102px;
    height: 112px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    bottom: -56px;
    top: auto;
    right: 60px;
    z-index: 999;

    @media (min-width: 767px) {
      bottom: calc(50% - 112px);
      right: 0;
    }

    @media only screen and (max-width: 979px) and (max-height: 400px) and (orientation: landscape) {
      bottom: calc(50% - 112px);
    }

    &:hover {
      transition: 0.3s ease;
      background: #6536FF !important;
    }

    @media (max-width: 767px) {
      width: 83px;
      height: 60px;
      bottom: 35px;
      right: 0;
    }

    &:before {
      background: url("../img/bioArrow.svg") no-repeat;
      background-position: center;
      content: "";
      width: 6px;
      height: 12px;
      display: block;
      opacity: 1;
    }

    &.slick-prev {
      left: auto;
      right: 162px;

      @media (min-width: 767px) {
        right: auto;
        left: 0;
      }

      @media (max-width: 767px) {
        right: 83px;
      }

      &:before {
        transform: rotate(180deg);
      }
    }
  }

}

.video_home .goHome:after, .allvideo:after, .allvideo:hover:after, .video_home .goHome:hover:after, .video_home .goHome {

  @media (max-width: 767px) {
    background: #000;
  }
}

.allvideo {
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
  }
}

.allvideo {
  @media only screen and (max-width: 979px) and (max-height: 400px) and (orientation: landscape) {
    top: 20px;
  }
}

.video_home a {
  @media (min-width: 767px) {
    bottom: 55px;
  }
  @media only screen and (max-width: 979px) and (max-height: 400px) and (orientation: landscape) {
    bottom: 65px;
  }
}

.custom-controls {
  position: absolute;
  bottom: 41px;
  left: 190px;
  display: flex;
  align-items: center;
  gap: 19px;
  width: 68%;
  @media (max-width: 1433px) {
    width: 63%;
  }
  @media (max-width: 1236px) {
    width: 58%;
  }
  @media (max-width: 1094px) {
    width: 53%;
  }
  @media (max-width: 997px) {
    bottom: 26px;
    width: 53%;
    left: 136px;
  }
  @media (max-width: 850px) {
    width: 46%;
  }

  @media (max-width: 767px) {
    bottom: 53px;
    width: 90%;
    left: 10px;
  }

  input[type=range] {
    width: 100%;
  }

  .play_btn {
    border: none;
    width: 17px;
    height: 17px;

    &.pause {
      background: url("../img/pause.svg") no-repeat;
      background-size: 127%;
      background-position-x: right;
    }

    &.play {
      background: url("../img/play.svg") no-repeat;
      background-size: contain;
    }
  }
}

.videoGridContainer {
  position: relative;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background: url("../img/play.svg") no-repeat;
    background-size: 50px;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease;
    cursor: pointer;

    @media (max-width: 997px) {
      background-size: 30px;
    }
  }

  &:hover:after {
    background-size: 55px;

    @media (max-width: 997px) {
      background-size: 35px;
    }
  }
}

/*generated with Input range slider CSS style generator (version 20211225)
https://toughengineer.github.io/demo/slider-styler*/
input[type=range].styled-slider {
  height: 4px;
  -webkit-appearance: none;
}

/*progress support*/
input[type=range].styled-slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 3px + var(--ratio) * (100% - 3px));
}

/*webkit*/
input[type=range].styled-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 3px;
  height: 12px;
  border-radius: 0;
  background: #FFF;
  border: none;
  box-shadow: none;
  margin-top: calc(max((6px - 1px - 1px) * 0.5, 0px) - 12px * 0.5);
}

input[type=range].styled-slider::-webkit-slider-runnable-track {
  height: 4px;
  border: none;
  border-radius: 0;
  background: #cecece;
  box-shadow: none;
}

input[type=range].styled-slider::-webkit-slider-thumb:hover {
  background: #fff;
}

input[type=range].styled-slider:hover::-webkit-slider-runnable-track {
  background: #e5e5e5;
}

input[type=range].styled-slider::-webkit-slider-thumb:active {
  background: #fff;
}

input[type=range].styled-slider:active::-webkit-slider-runnable-track {
  background: #fff;
}

//slider pass
input[type=range].styled-slider.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient(#fff, #fff) 0/var(--sx) 100% no-repeat, #cecece;
}

input[type=range].styled-slider.slider-progress:hover::-webkit-slider-runnable-track {
  background: linear-gradient(#fff, #fff) 0/var(--sx) 100% no-repeat, #cecece;
}

input[type=range].styled-slider.slider-progress:active::-webkit-slider-runnable-track {
  background: linear-gradient(#fff, #fff) 0/var(--sx) 100% no-repeat, #cecece;
}

/*mozilla*/
input[type=range].styled-slider::-moz-range-thumb {
  width: 3px;
  height: 12px;
  border-radius: 0;
  background: #fff;
  border: none;
  box-shadow: none;
}

input[type=range].styled-slider::-moz-range-track {
  height: max(calc(6px - 1px - 1px), 0px);
  border: none;
  border-radius: 0.5em;
  background: #FFFFFF99;
  box-shadow: none;
}

input[type=range].styled-slider::-moz-range-thumb:hover {
  background: #fff;
}

input[type=range].styled-slider:hover::-moz-range-track {
  background: #e5e5e5;
}

input[type=range].styled-slider::-moz-range-thumb:active {
  background: #cecece;
}

input[type=range].styled-slider:active::-moz-range-track {
  background: #f5f5f5;
}

input[type=range].styled-slider.slider-progress::-moz-range-track {
  background: linear-gradient(#fff, #fff) 0/var(--sx) 100% no-repeat, #cecece;
}

input[type=range].styled-slider.slider-progress:hover::-moz-range-track {
  background: linear-gradient(#fff, #fff) 0/var(--sx) 100% no-repeat, #cecece;
}

input[type=range].styled-slider.slider-progress:active::-moz-range-track {
  background: linear-gradient(#fff, #fff) 0/var(--sx) 100% no-repeat, #cecece;
}

/*ms*/
input[type=range].styled-slider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type=range].styled-slider::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type=range].styled-slider::-ms-thumb {
  width: 2em;
  height: 2em;
  border-radius: 1em;
  background: #fff;
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: 0;
  box-sizing: border-box;
}

input[type=range].styled-slider::-ms-track {
  height: 1em;
  border-radius: 0.5em;
  background: #cecece;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
}

input[type=range].styled-slider::-ms-thumb:hover {
  background: #fff;
}

input[type=range].styled-slider:hover::-ms-track {
  background: #cecece;
}

input[type=range].styled-slider::-ms-thumb:active {
  background: #cecece;
}

input[type=range].styled-slider:active::-ms-track {
  background: #cecece;
}

input[type=range].styled-slider.slider-progress::-ms-fill-lower {
  height: max(calc(1em - 1px - 1px), 0px);
  border-radius: 0.5em 0 0 0.5em;
  margin: -1px 0 -1px -1px;
  background: #fff;
  border: none;
  border-right-width: 0;
}

input[type=range].styled-slider.slider-progress:hover::-ms-fill-lower {
  background: #fff;
}

input[type=range].styled-slider.slider-progress:active::-ms-fill-lower {
  background: #fff;
}

.mute_btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  margin-left: 10px;
}

.mute_btn {
  position: relative;
  height: 27px;

  &:before {
    content: '';
    width: 28px;
    height: 27px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.muted:before {
    background: url("../img/mute.svg") no-repeat center left 1.5px;
    background-size: 25px;
  }


  &.unmuted:before {
    background: url("../img/vol.svg") no-repeat center left;
    background-size: 25px;
  }

}

