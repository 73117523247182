@import './variables';

#menu1 {
  position: fixed;
  opacity: 1;
  transition: 1000ms all cubic-bezier(0.19, 1, 0.22, 1);
  right: 60px;
  bottom: 60px;
  z-index: 999;
  @media (max-width: 1360px) {
    bottom: auto;
    top: 60px;
  }
  @media (max-width: 997px) {
    right: 20px;
    top: 25px;
    height: 100%;
  }

  .menu-inner {
    position: absolute;
    right: -2000px;
    width: max-content;
    top: 0;

    @media (max-width: 997px) {
      top: -40px;
      height: 100%;
      background: $main_color;
      opacity: 0.9;
      padding-top: 87px;
    }

    ul {
      display: flex;
      gap: 0;

      @media (max-width: 997px) {
        flex-direction: column;
      }

      li {
        transform: translateX(2000px);
        transition: 0.2s ease;
        @for $i from 0 through $menuItems {
          &:nth-child(#{$i + 1}) {
            transition-delay: $transition+(60ms*($i));
          }
        }

        a {
          font-size: 14px;
          display: block;
          position: relative;
          color: $color_white;
          user-select: none;
          padding: 3px 14px;

          @media (max-width: 997px) {
            font-size: 24px;
            padding: 12px 40px 12px 20px;
          }

          &.active {
            color: $color_white;
          }

          &:after {
            display: block;
            position: absolute;
            left: 0;
            width: 0;
            bottom: 0;
            height: 100%;
            background-color: #714BEE;
            content: "";
            transition: width 0.2s ease-out;
            z-index: -1;
            transform: matrix(1, 0, -0.5, 1, 0, 0);
          }

          &:hover {
            color: $color_white;
            text-decoration: none;

            &:after {
              width: 100%;
              @media (max-width: 997px) {
               width: 0;
              }
            }

            &:focus:after {
              width: 100%;
            }
          }
        }
      }
    }

  }

  &.desctop_open .menu-inner {
    @media (min-width: 997px) {
      right: 60px !important;

      ul li {
        transform: translateX(0) !important;
      }
    }
  }
  &.expanded .menu-inner {
    right: 60px;
    @media (max-width: 997px) {
      right: -20px;
      width: 70vw;
    }

    ul li {
      transform: translateX(0);
    }
  }

  &.expanded {
    &:after {
      @media (max-width: 997px) {
        content: '';
        position: absolute;
        top: -8px;
        right: 0;
        display: block;
        width: 33px;
        height: 33px;
        background: url("../img/close.svg") no-repeat;
        background-size: contain;
        background-position: center;
      }
    }

    &:before {
      @media (max-width: 997px) {
        content: '';
        position: absolute;
        top: -40px;
        right: -20px;
        width: 100vw;
        height: 100vh;
        min-height: 100vh;
        background: rgba(0, 0, 0, 0.60);
      }
    }

    .hamburger {
      @media (max-width: 997px) {
        display: none;
      }

      svg {
          rect {
            @media (max-width: 997px) {
              transition: 0.2s ease;
              &:nth-child(3) {
                width: 45px;
                x: 0;
              }
              &:nth-child(2) {
                width: 30px;
                x: 15px;
              }
              &:nth-child(1) {
                width: 35px;
                x: 10px;
              }
            }

          }
      }

    }
  }
  &.expanded.innerPageMenu {
    height: 100%;
    &:before {
      top: 0;
      right: 0;
    }
    &:after {
      top: 10px;
      right: 10px;
    }
    .menu-inner {
      top: 0;
      right: 0;
    }
  }
  &.innerPageMenu {
    z-index: 999999;
    top: 0;
    height: auto;
    width: 80px;
    right: 0;
    justify-content: center;
    display: none;
    padding-top: 25px;

    .main-social.social-menu {
      bottom: 130px;
    }
    @media (max-width: 997px) {
      display: flex;
    }

  }


}

.hamburger {
  cursor: pointer;
  padding-top: 3px;

  svg {
    &:hover {
      rect {
        @media (max-width: 767px) {
          transition: 0.2s ease;
          &:nth-child(3) {
            width: 45px;
            x: 0;
          }
          &:nth-child(2) {
            width: 30px;
            x: 15px;
          }
          &:nth-child(1) {
            width: 35px;
            x: 10px;
          }
        }

      }
    }
  }
}


#menu1 {
  ul {
    padding: 0;
    list-style: none;

    li {
      color: #fff;
      padding: 0;
    }
  }

  .logoMenu {
    margin-bottom: 12px;
    img {
      width: 31px;
    }
  }
  .main-social {
    position: absolute;
    right: 60px;
    top: 60px;
    display: none;
    @media (max-width: 1360px) {
      top: auto;
      bottom: 60px;
    }
    @media (max-width: 767px) {
      display: block;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;

      li {
        a {
          display: block;
          width: 24px;
          height: 24px;
          background-position: center;
          transition: 0.2s ease;

          &.telegram {
            background: url("../img/telegram.svg") no-repeat;
            background-size: cover;
            background-repeat: no-repeat;

            &:hover {
              background: url("../img/telegram_h.svg") no-repeat;
              background-size: cover;
            }
          }

          &.youtube {
            background: url("../img/youtube.svg");
            background-size: cover;
            background-repeat: no-repeat;


            &:hover {
              background: url("../img/youtube_h.svg") no-repeat;
              background-size: cover;
            }
          }

          &.rt {
            background: url("../img/rt.svg");
            background-size: cover;
            background-repeat: no-repeat;

            &:hover {
              background: url("../img/rt_h.svg") no-repeat;
              background-size: cover;
            }
          }

          @media (max-width: 1360px) {
            width: 31px;
            height: 31px;
          }
        }
      }
    }
  }

  .main-social.social-menu {
    right: auto;
    left: 20px;
    bottom: 105px;

    ul {
      flex-direction: row;
      gap: 12px;

      li a {
        padding: 0;
      }
    }
  }

}